import { createFeatureSelector, createSelector } from '@ngrx/store';
import { difference, get, mapValues } from 'lodash';

import { ICoreState } from '../../core/+state/core.reducer';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectUserMePermissionstate =
  createFeatureSelector<State>('userMePermissions');

export const selectCoreStore = createFeatureSelector<ICoreState>('core');

export const selectUserMePermissionsError = createSelector(
  selectUserMePermissionstate,
  getError,
);

export const selectUserMePermissionsIsLoading = createSelector(
  selectUserMePermissionstate,
  getIsLoading,
);

export const selectUserMePermissionsFeatures = createSelector(
  selectUserMePermissionstate,
  (state) => get(state.userMePermissions, 'features', []),
);

export const selectUserMePropertyPermissionsFeatures = createSelector(
  selectUserMePermissionstate,
  (state) => Object.keys(state.propertyPermissions || {}),
);

export const selectUserMePropertyPermissionsCanWriteInFeature = (
  feature: string,
) =>
  createSelector(selectUserMePermissionstate, (state) => {
    return mapValues(
      (state.propertyPermissions || {})[feature],
      (value) => value === 2,
    );
  });

export const selectUserMeFeaturesThatCanBeSeen = (properties: number[]) =>
  createSelector(selectUserMePermissionstate, (state) => {
    return Object.keys(state.propertyPermissions || {}).filter((feature) => {
      return properties.every((propertyId: number) => {
        return state.propertyPermissions[feature][propertyId] > 0;
      });
    });
  });

export const selectUserMePermissionsFeatureProperties = (feature: string) =>
  createSelector(selectUserMePermissionstate, (state) => {
    if (feature === 'any') {
      return Object.values(get(state, 'userMePermissions.currentUser', {})).map(
        ({ property_id }) => +property_id,
      );
    }
    return Object.keys(get(state.propertyPermissions, feature, {}))
      .filter((propertyId) => {
        return state.propertyPermissions[feature][propertyId] > 0;
      })
      .map(Number);
  });

export const selectCanReadFeatureInAllProperties = (
  feature: string,
  properties: number[],
) =>
  createSelector(
    selectUserMePermissionsFeatureProperties(feature),
    (propertiesWithPermission: number[]) => {
      const a = [...(properties || [])].sort();
      const b = [...(propertiesWithPermission || [])].sort();
      return (difference(a, b) as any[])?.length === 0;
    },
  );

export const selectUserMePermissionsByLevel = (
  feature: string,
  level: number,
) =>
  createSelector(
    selectUserMePermissionstate,
    selectCoreStore,
    (state, coreState) => {
      const allPropertiesSelected = get(coreState, 'allProperties', []);

      const propertyFeaturePermissions = get(
        state,
        `propertyPermissions.${feature}`,
        {},
      );

      const atLeastOneCanWrite = allPropertiesSelected.some((property) => {
        return get(propertyFeaturePermissions, property.id, {}) >= level;
      });

      return Object.keys(propertyFeaturePermissions).reduce(
        (permissions, propertyId) => ({
          ...permissions,
          [propertyId]: !atLeastOneCanWrite
            ? false
            : propertyFeaturePermissions[propertyId] >= level,
        }),
        {},
      );
    },
  );

export const selectUserMeCanWriteInFeature = (feature: string) =>
  selectUserMePermissionsByLevel(feature, 2);

export const selectUserMeCanReadInFeature = (feature: string) =>
  selectUserMePermissionsByLevel(feature, 1);

export const selectCurrentUser = createSelector(
  selectUserMePermissionstate,
  (state) => get(state, 'userMePermissions.currentUser', null),
);
