import { createAction, props } from '@ngrx/store';

import { UserMePermission } from '../../models';

export const loadUserMePermissionsRequest = createAction(
  '[User Me Permissions] Load Request',
  props<{ userId: number }>(),
);

export const loadUserMePermissionsSuccess = createAction(
  '[User Me Permissions] Load Success',
  props<{ userMePermissions: UserMePermission }>(),
);

export const loadUserMePermissionsFailure = createAction(
  '[User Me Permissions] Load Failure',
  props<{ error: any }>(),
);

export const resetUserMePermissionsState = createAction(
  '[User Me Permissions] Reset State',
);
