import { UserMePermission } from '../../models';

export interface State {
  userMePermissions: UserMePermission;
  propertyPermissions: any;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  userMePermissions: null,
  propertyPermissions: null,
  isLoading: false,
  error: null,
};
