import { UserMePermission } from '@app/models';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export type PermissionFieldForm = 'nobody' | 'reading' | 'readingWriting';

export interface UserMePermissionField {
  create: boolean;
  update: boolean;
  cancel: boolean;
  read: boolean;
}

const getPropertyPermissions = (userPermissionsData: UserMePermission) => {
  const getPermissionLevel = (fileds: UserMePermissionField): number => {
    const { cancel, read, update, create } = fileds;
    if (!cancel && !read && !update && !create) {
      return 0;
    }
    if (create || update || cancel) {
      return 2;
    }
    if (read && !cancel && !update && !create) {
      return 1;
    }
    return 0;
  };

  const { currentUser, features } = userPermissionsData;

  return features.reduce(
    (pages, page) => ({
      ...pages,
      [page]: currentUser.reduce((properties, { permissions, property_id }) => {
        return {
          ...properties,
          [property_id]: permissions[page]
            ? getPermissionLevel({
                cancel: !!permissions[page].delete,
                create: !!permissions[page].create,
                read: !!permissions[page].read,
                update: !!permissions[page].update,
              })
            : 0,
        };
      }, {}),
    }),
    {},
  );
};

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadUserMePermissionsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadUserMePermissionsSuccess,
    (state, { userMePermissions }) => ({
      ...state,
      userMePermissions: userMePermissions,
      propertyPermissions: getPropertyPermissions(userMePermissions),
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadUserMePermissionsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetUserMePermissionsState, () => fromState.initialState),
);

export function userMePermissionsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
